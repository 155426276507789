import { AppOpeningAnimation } from "@swan-io/lake/src/components/AppOpeningAnimation";
import { Box } from "@swan-io/lake/src/components/Box";
import { SmsOpeningAnimation } from "@swan-io/lake/src/components/SmsOpeningAnimation";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { memo } from "react";
import { StyleSheet, View } from "react-native";

const ANIMATION_WIDTH = 144;
const BORDER_SIZE = 6;
const PHONE_WIDTH = ANIMATION_WIDTH + 2 * BORDER_SIZE;
const COLOR_BACK_SIZE = PHONE_WIDTH + 24;

const styles = StyleSheet.create({
  base: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    zIndex: -1,
  },
  circle: {
    backgroundColor: colors.gray[900],
    position: "absolute",
    height: COLOR_BACK_SIZE,
    width: COLOR_BACK_SIZE,
    borderRadius: COLOR_BACK_SIZE / 2,
  },
  pulse: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    borderColor: colors.gray[50],
    borderRadius: COLOR_BACK_SIZE / 2,
    borderStyle: "solid",
    borderWidth: 1,
    transform: "scale(0)",

    animationKeyframes: {
      from: { opacity: 0, transform: "scale(1)" },
      "25%": { opacity: 0.75 },
      to: { opacity: 0, transform: "scale(3)" },
    },

    animationDuration: "4.5s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
  },
  smallDelay: {
    animationDelay: "1.5s",
  },
  mediumDelay: {
    animationDelay: "3s",
  },
  phone: {
    width: PHONE_WIDTH,
    height: "auto",
  },
  cameraZone: {
    position: "absolute",
    top: 12,
    left: 0,
    right: 0,
  },
  faceId: {
    borderRadius: 3,
    backgroundColor: colors.gray[800],
    height: 6,
    width: 20,
  },
  camera: {
    borderRadius: 3,
    backgroundColor: colors.gray[800],
    height: 6,
    width: 6,
  },
});

type Props = {
  mainColor: string;
  pulseColor: string;
  notificationSettings?: { notificationAccentColor: string; notificationLogoUrl: string };
};

export const Phone = memo(({ mainColor, pulseColor, notificationSettings }: Props) => {
  const pulseStyle = { borderColor: pulseColor };

  return (
    <View style={styles.base}>
      <View style={[styles.circle, { backgroundColor: mainColor }]}>
        <View role="none" style={[styles.pulse, pulseStyle]} />
        <View role="none" style={[styles.pulse, styles.smallDelay, pulseStyle]} />
        <View role="none" style={[styles.pulse, styles.mediumDelay, pulseStyle]} />
      </View>

      <View>
        {isNotNullish(notificationSettings) ? (
          <AppOpeningAnimation
            style={styles.phone}
            accentColor={notificationSettings.notificationAccentColor}
            appLogo={notificationSettings.notificationLogoUrl}
          />
        ) : (
          <SmsOpeningAnimation style={styles.phone} />
        )}

        <Box direction="row" justifyContent="center" style={styles.cameraZone}>
          <View style={styles.faceId} />
          <Space width={4} />
          <View style={styles.camera} />
        </Box>
      </View>
    </View>
  );
});
