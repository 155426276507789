import { P } from "ts-pattern";

export const sensitiveInfoAuthenticatorOnlyPattern = P.union(
  {
    purpose: "PrintPhysicalCard",
    envType: P.union("Live", "Sandbox"),
    token: P.string,
    productCode: P.string,
    redirectTo: P.optional(P.string),
  },
  {
    purpose: P.union("ViewPhysicalCardPin"),
    envType: P.union("Live", "Sandbox"),
    requestId: P.string,
    token: P.string,
    expirationDate: P.string,
    redirectTo: P.optional(P.string),
  },
);

export const sensitiveInfoAnyDevicePattern = {
  purpose: P.union("ViewCardNumbers", "AddCard"),
  envType: P.union("Live", "Sandbox"),
  requestId: P.string,
  token: P.string,
  expirationDate: P.string,
  redirectTo: P.optional(P.string),
};

export const sensitiveInfoAuthenticatorPattern = P.union(
  sensitiveInfoAnyDevicePattern,
  sensitiveInfoAuthenticatorOnlyPattern,
);

export type SensitiveInfoRemote = P.infer<typeof sensitiveInfoAnyDevicePattern>;
export type SensitiveInfoAuthenticator = P.infer<typeof sensitiveInfoAuthenticatorPattern>;
