import { BorderedIcon } from "@swan-io/lake/src/components/BorderedIcon";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useEffect } from "react";
import { Layout } from "../../components/Layout";
import { t } from "../../utils/i18n";
import { clearSignMachine } from "../../utils/signMachine";
import { clearLocalStorage } from "../../utils/storage";

export const AuthenticatorClearPage = () => {
  useEffect(() => {
    clearLocalStorage();
    clearSignMachine();
  }, []);

  return (
    <Layout envType="Live">
      <BorderedIcon
        color="partner"
        name="lake-eraser"
        size={100}
        padding={24}
        style={commonStyles.centerSelf}
      />

      <Space height={24} />

      <LakeHeading level={1} variant="h3" align="center">
        {t("clearPage.title")}
      </LakeHeading>

      <Space height={8} />
      <LakeText align="center">{t("clearPage.text")}</LakeText>
    </Layout>
  );
};
