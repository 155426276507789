import { AsyncData, Result } from "@swan-io/boxed";
import { ClientContext, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LoadingView as LakeLoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, radii } from "@swan-io/lake/src/constants/design";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { Fragment } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { LoadingView } from "../components/LoadingView";
import {
  CardPinInfosDocument,
  CardPinInfosQuery,
  CardWidgetPageDocument,
} from "../graphql/live-unauthenticated";
import { useSmartPinJs } from "../hooks/useSmartPinJs";
import { env } from "../utils/env";
import { liveUnauthenticatedClient, sandboxUnauthenticatedClient } from "../utils/gql";
import { t } from "../utils/i18n";
import { SensitiveInfoAuthenticator } from "../utils/sensitiveInfo";
import { Card } from "./Card";
import { ErrorView } from "./ErrorView";
import { Redirect } from "./Redirect";

const CARD_REAL_HEIGHT = 55; // mm
const CARD_REAL_WIDTH = 85; // mm
const CARD_WEB_WIDTH = 390; // px

const styles = StyleSheet.create({
  base: {
    maxWidth: CARD_WEB_WIDTH,
    margin: "auto",
    width: "100%",
  },
  basePin: {
    maxWidth: 264,
  },
  baseChoosePin: {
    margin: 0,
    marginHorizontal: "auto",
    flexGrow: 1,
  },
  pinNumber: {
    backgroundColor: colors.gray[50],
    width: 60,
    paddingVertical: 12,
    borderRadius: radii[4],
  },
  pinNumberText: {
    color: colors.gray[900],
    fontSize: 24,
  },
  cardRadio: {
    aspectRatio: CARD_REAL_WIDTH / CARD_REAL_HEIGHT, // TODO: Backward compatibility
  },
});

type Props = {
  token: string;
  requestId: string;
};

const ViewCardNumbers = ({ token, requestId }: Props) => {
  const [data] = useQuery(CardWidgetPageDocument, { input: { token, requestId } });

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <LakeLoadingView style={styles.cardRadio} />
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(
      AsyncData.P.Done(
        Result.P.Ok({
          cardInfos: P.union(P.nullish, { cardInfos: { __typename: "MaskedCardInfo" } }),
        }),
      ),
      () => <ErrorView />,
    )
    .with(
      AsyncData.P.Done(
        Result.P.Ok({ cardInfos: P.select({ cardInfos: { __typename: "CardInfo" } }) }),
      ),
      cardInfos => {
        return (
          <Card
            cardDesign={cardInfos.cardDesignUrl}
            theme={cardInfos.cardBackgroundType}
            holderName={cardInfos.cardInfos.cardHolderName}
            pan={cardInfos.cardInfos.panIframeUrl}
            expiryDate={cardInfos.cardInfos.expiryDateIframeUrl}
            cvv={cardInfos.cardInfos.cvvIframeUrl}
          />
        );
      },
    )
    .otherwise(() => null);
};

const CardPinCode = ({ pin }: { pin: string }) => (
  <View>
    <LakeHeading level={1} variant="h5">
      {t("text.pinCodeLabel")}
    </LakeHeading>

    <Space height={16} />

    <Box direction="row" justifyContent="center">
      {pin.split("").map((number, index) => (
        <Fragment key={index}>
          {index !== 0 && <Space width={8} />}

          <Box style={styles.pinNumber} justifyContent="center" alignItems="center">
            <LakeText variant="semibold" style={styles.pinNumberText}>
              {number}
            </LakeText>
          </Box>
        </Fragment>
      ))}
    </Box>
  </View>
);

const ViewPhysicalCardPinLiveContents = ({ data }: { data: CardPinInfosQuery }) => {
  const [{ fetching: fetchingPin, pin = "", error: errorPin = "" }] = useSmartPinJs(
    data.cardPINInfos,
  );

  const cardPINInfos = data.cardPINInfos;

  if (fetchingPin) {
    return <LoadingView id="SensitiveInfo.FetchingPIN" />;
  }

  if (errorPin || !cardPINInfos) {
    return <ErrorView />;
  }

  return pin ? <CardPinCode pin={pin} /> : null;
};

const ViewPhysicalCardPinLive = ({ token, requestId }: Props) => {
  const [data] = useQuery(CardPinInfosDocument, { input: { cardId: token, requestId } });

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <LoadingView id="SensitiveInfo.ViewPhysicalCardPinLive" />
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), () => (
      <LakeHeading level={1} variant="h5">
        {t("text.pinCodeNotAvailable")}
      </LakeHeading>
    ))
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      return <ViewPhysicalCardPinLiveContents data={data} />;
    })
    .exhaustive();
};

const ViewPhysicalCardPinSandbox = ({ token, requestId }: Props) => {
  const [data] = useQuery(CardPinInfosDocument, { input: { cardId: token, requestId } });

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <LoadingView id="SensitiveInfo.ViewPhysicalCardPinSandbox" />
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), () => {
      return <CardPinCode pin="1234" />;
    })
    .exhaustive();
};

export const ChoosePhysicalCardPinLive = ({
  productCode,
  token,
}: {
  productCode: string;
  token: string;
}) => {
  const returnUrl = new URL(env.IDENTITY_URL);
  returnUrl.pathname = "/done";
  const url = new URL(env.CHOOSE_PIN_ROOT_URL);
  url.searchParams.append("tokenAccess", token);
  url.searchParams.append("codeProduit", productCode);
  url.searchParams.append("returnUrl", returnUrl.toString());

  return <Redirect to={url.toString()} clearSession={true} />;
};

export const ChoosePhysicalCardPinSandbox = () => {
  return (
    <Box style={commonStyles.fill} direction="column" justifyContent="center" alignItems="center">
      <CardPinCode pin="1234" />
      <Space height={32} />
    </Box>
  );
};

type SensitiveInfoProps = {
  sensitiveInfo: SensitiveInfoAuthenticator;
  onClose: (redirectTo: string) => void;
};

export const SensitiveInfoViewer = ({ sensitiveInfo, onClose }: SensitiveInfoProps) => {
  const redirectTo = sensitiveInfo.redirectTo;

  return (
    <View
      style={[
        styles.base,
        sensitiveInfo.purpose === "ViewPhysicalCardPin" && styles.basePin,
        sensitiveInfo.purpose === "PrintPhysicalCard" && styles.baseChoosePin,
      ]}
    >
      {match(sensitiveInfo)
        .with(
          { purpose: "ViewCardNumbers", envType: "Live" },
          { purpose: "AddCard", envType: "Live" },
          ({ requestId, token }) => (
            <ClientContext.Provider value={liveUnauthenticatedClient}>
              <ViewCardNumbers requestId={requestId} token={token} />
            </ClientContext.Provider>
          ),
        )
        .with(
          { purpose: "ViewCardNumbers", envType: "Sandbox" },
          { purpose: "AddCard", envType: "Sandbox" },
          ({ requestId, token }) => (
            <ClientContext.Provider value={sandboxUnauthenticatedClient}>
              <ViewCardNumbers requestId={requestId} token={token} />
            </ClientContext.Provider>
          ),
        )
        .with({ purpose: "ViewPhysicalCardPin", envType: "Live" }, ({ requestId, token }) => (
          <ClientContext.Provider value={liveUnauthenticatedClient}>
            <ViewPhysicalCardPinLive token={token} requestId={requestId} />
          </ClientContext.Provider>
        ))
        .with({ purpose: "ViewPhysicalCardPin", envType: "Sandbox" }, ({ requestId, token }) => (
          <ClientContext.Provider value={sandboxUnauthenticatedClient}>
            <ViewPhysicalCardPinSandbox token={token} requestId={requestId} />
          </ClientContext.Provider>
        ))
        .with({ purpose: "PrintPhysicalCard", envType: "Sandbox" }, () => (
          <ChoosePhysicalCardPinSandbox />
        ))
        .with({ purpose: "PrintPhysicalCard", envType: "Live" }, ({ token, productCode }) => (
          <ChoosePhysicalCardPinLive token={token} productCode={productCode} />
        ))
        .exhaustive()}

      {isNotNullishOrEmpty(redirectTo) && sensitiveInfo.purpose !== "PrintPhysicalCard" && (
        <>
          <Space height={20} />

          <LakeButton mode="tertiary" onPress={() => onClose(redirectTo)}>
            {t("button.closeSensitiveCardOperation")}
          </LakeButton>
        </>
      )}
    </View>
  );
};
