import { Future, Result } from "@swan-io/boxed";

const wrapRequest = <T>(
  request: IDBRequest<T>,
  {
    attempts,
    timeout,
    onUpgradeNeeded,
  }: { attempts: number; timeout: number; onUpgradeNeeded?: () => void },
) => {
  return Future.retry(
    () =>
      Future.make<Result<T, Error>>(resolve => {
        const timeoutId = setTimeout(() => {
          resolve(Result.Error(new Error("IndexedDB request timed out")));
          stopListening();
        }, timeout);

        const wrappedOnUpgradeNeeded = () => {
          if (onUpgradeNeeded != null) {
            Result.fromExecution<void, DOMException>(() => onUpgradeNeeded()).tapError(error => {
              resolve(Result.Error(error));
            });
          }
        };

        const onSuccess = () => {
          resolve(Result.Ok(request.result));
          stopListening();
        };

        const onError = () => {
          resolve(Result.Error(request.error as DOMException));
          stopListening();
        };

        const stopListening = () => {
          request.removeEventListener("success", onSuccess);
          request.removeEventListener("error", onError);
          if (onUpgradeNeeded != null) {
            request.removeEventListener("upgradeneeded", wrappedOnUpgradeNeeded);
          }
          clearTimeout(timeoutId);
        };

        request.addEventListener("success", onSuccess);
        request.addEventListener("error", onError);
        if (onUpgradeNeeded != null) {
          request.addEventListener("upgradeneeded", wrappedOnUpgradeNeeded);
        }

        return () => stopListening();
      }),
    { max: attempts },
  );
};

export const open = ({
  databaseName,
  databaseVersion,
  storeName,
}: {
  databaseName: string;
  databaseVersion?: number;
  storeName: string;
}) => {
  return Future.value(
    Result.fromExecution<IDBOpenDBRequest, Error>(() =>
      indexedDB.open(databaseName, databaseVersion),
    ),
  ).flatMapOk(dbOpen =>
    wrapRequest(dbOpen, {
      attempts: 3,
      timeout: 500,
      onUpgradeNeeded: () => {
        dbOpen.result.createObjectStore(storeName);
      },
    }),
  );
};

export const get = (
  db: IDBDatabase,
  storeName: string,
  key: string,
): Future<Result<unknown, Error>> => {
  return Future.value(
    Result.fromExecution<IDBTransaction, Error>(() =>
      db.transaction(storeName, "readonly"),
    ).flatMap(transaction =>
      Result.fromExecution<IDBObjectStore, Error>(() => transaction.objectStore(storeName)),
    ),
  ).flatMapOk(objectStore =>
    wrapRequest(objectStore.get(key), {
      attempts: 3,
      timeout: 500,
    }),
  );
};

export const set = (
  db: IDBDatabase,
  storeName: string,
  key: string,
  value: unknown,
): Future<Result<unknown, Error>> => {
  return Future.value(
    Result.fromExecution<IDBTransaction, Error>(() =>
      db.transaction(storeName, "readwrite"),
    ).flatMap(transaction =>
      Result.fromExecution<IDBObjectStore, Error>(() => transaction.objectStore(storeName)),
    ),
  ).flatMapOk(objectStore =>
    wrapRequest(objectStore.put(value, key), {
      attempts: 3,
      timeout: 500,
    }),
  );
};

export const clear = (db: IDBDatabase, storeName: string): Future<Result<unknown, Error>> => {
  return Future.value(
    Result.fromExecution<IDBTransaction, Error>(() =>
      db.transaction(storeName, "readwrite"),
    ).flatMap(transaction =>
      Result.fromExecution<IDBObjectStore, Error>(() => transaction.objectStore(storeName)),
    ),
  ).flatMapOk(objectStore =>
    wrapRequest(objectStore.clear(), {
      attempts: 3,
      timeout: 500,
    }),
  );
};
