import { getLocation } from "@swan-io/chicane";
import { useLayoutEffect } from "react";
import { safeRedirect } from "../utils/routes";

type Props = {
  to: string;
  clearSession?: boolean;
};

export const Redirect = ({ to, clearSession = false }: Props) => {
  useLayoutEffect(() => {
    const location = getLocation().toString();

    if (to !== location) {
      safeRedirect(to, { clearSession });
    }
  }, [to, clearSession]);

  return null;
};
