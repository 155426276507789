import { Array, Dict, Option, Result } from "@swan-io/boxed";
import { getLocation } from "@swan-io/chicane";
import { ClientContext } from "@swan-io/graphql-client";
import { ErrorBoundary } from "@swan-io/lake/src/components/ErrorBoundary";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Suspense, lazy, useState } from "react";
import { P, match } from "ts-pattern";
import { BrandedApp } from "./BrandedApp";
import { ErrorView } from "./components/ErrorView";
import { CardChoosePinPage } from "./pages/CardChoosePinPage";
import { DonePage } from "./pages/DonePage";
import { ExpiredLinkPage } from "./pages/ExpiredLinkPage";
import { LogoutCallbackPage } from "./pages/LogoutCallbackPage";
import { LogoutPage } from "./pages/LogoutPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { AuthenticatorClearPage } from "./pages/authenticator/AuthenticatorClearPage";
import {
  adminClient,
  liveExposedInternalClient,
  liveUnauthenticatedClient,
  sandboxExposedInternalClient,
  sandboxUnauthenticatedClient,
} from "./utils/gql";
import { logFrontendError } from "./utils/logger";
import { Router } from "./utils/routes";
import {
  EntryParam,
  MobilePhoneNumber,
  OtpParams,
  Source,
  SubcriptionChannelNameByConsentId,
} from "./utils/session";

const CardWidgetPage = lazy(() =>
  import("./pages/CardWidgetPage").then(({ CardWidgetPage }) => ({ default: CardWidgetPage })),
);

const search = getLocation().search;

const entryParam = match(search)
  .with({ login_challenge: P.select(P.string) }, loginChallenge => Option.Some({ loginChallenge }))
  .with({ consent_challenge: P.select(P.string) }, consentChallenge =>
    Option.Some({ consentChallenge }),
  )
  .with({ consentId: P.string, env: P.union("Sandbox", "Live") }, ({ consentId, env }) =>
    Option.Some({
      consentId,
      env,
    }),
  )
  .otherwise(() => Option.None());

match(entryParam)
  .with(Option.P.Some(P.select()), value => EntryParam.set(value))
  .otherwise(() => {});

const otpParams = match(search)
  .with({ code: P.string, expireAt: P.optional(P.string), requestId: P.string }, value =>
    Option.Some(value),
  )
  .otherwise(() => Option.None());

match(otpParams)
  .with(Option.P.Some(P.select()), value => OtpParams.set(value))
  .otherwise(() => {});

const qrCodeInitialPhoneNumber = match(search)
  .with({ qrCodeInitialPhoneNumber: P.select(P.string) }, value => Option.Some(value))
  .otherwise(() => Option.None());

match(qrCodeInitialPhoneNumber)
  .with(Option.P.Some(P.select()), value => MobilePhoneNumber.set(value))
  .otherwise(() => {});

// Infer source from initial page load
const source = match(search)
  .with({ qrCode: "true" }, () => "QRCode" as const)
  .with({ source: "App" }, () => "App" as const)
  .with(
    { code: P.string, expireAt: P.optional(P.string), requestId: P.string },
    () => "SMS" as const,
  )
  .otherwise(() => "Direct" as const);

Source.update(previousSource =>
  // If we have new params, override
  // Otherwise, keep the previous one
  match({ entryParam, previousSource })
    .with({ entryParam: Option.P.Some(P._) }, () => source)
    .otherwise(() => previousSource.getOr(source)),
);

const channel = match(search)
  .with({ channel: P.select(P.string) }, channel => {
    return Result.fromExecution(
      () => JSON.parse(atob(channel)) as Record<string, { channelName: string; expireAt: number }>,
    ).toOption();
  })
  .otherwise(() => Option.None());

const now = Date.now();

SubcriptionChannelNameByConsentId.update(value => ({
  ...value
    .map(values =>
      Dict.fromEntries(
        Array.filterMap(Dict.entries(values), ([key, value]) =>
          now <= value.expireAt ? Option.Some([key, value] as const) : Option.None(),
        ),
      ),
    )
    .getOr({}),
  ...channel.getOr({}),
}));

export const App = () => {
  const route = Router.useRoute([
    "AuthenticatorClear",
    "CardWidgetLive",
    "CardWidgetSandbox",
    "CardChoosePinLive",
    "CardChoosePinSandbox",
    "ExpiredLink",
    "Logout",
    "LogoutCallback",
    "Done",
  ]);

  const [entryParam] = useState(() => EntryParam.get());

  return (
    <ErrorBoundary
      fallback={() => <ErrorView />}
      key={route?.name}
      onError={error => logFrontendError(error)}
    >
      <ClientContext.Provider value={adminClient}>
        <Suspense fallback={<LoadingView />}>
          {match(route)
            .with({ name: "AuthenticatorClear" }, () => <AuthenticatorClearPage />)
            .with({ name: "CardWidgetLive" }, ({ params: { token } }) => (
              <ClientContext.Provider value={liveUnauthenticatedClient}>
                <CardWidgetPage token={token} />
              </ClientContext.Provider>
            ))
            .with({ name: "CardWidgetSandbox" }, ({ params: { token } }) => (
              <ClientContext.Provider value={sandboxUnauthenticatedClient}>
                <CardWidgetPage token={token} />
              </ClientContext.Provider>
            ))
            .with({ name: "CardChoosePinLive" }, ({ params: { token } }) => (
              <ClientContext.Provider value={liveExposedInternalClient}>
                <CardChoosePinPage token={token} env="Live" />
              </ClientContext.Provider>
            ))
            .with({ name: "CardChoosePinSandbox" }, ({ params: { token } }) => (
              <ClientContext.Provider value={sandboxExposedInternalClient}>
                <CardChoosePinPage token={token} env="Sandbox" />
              </ClientContext.Provider>
            ))
            .with({ name: "ExpiredLink" }, () => <ExpiredLinkPage />)
            .with({ name: "Logout" }, ({ params: { logout_challenge: logoutChallenge } }) => (
              <LogoutPage logoutChallenge={logoutChallenge} />
            ))
            .with({ name: "LogoutCallback" }, () => <LogoutCallbackPage />)
            .with({ name: "Done" }, () => <DonePage />)
            .with(P.nullish, () =>
              match(entryParam)
                .with(Option.P.None, () => <NotFoundPage />)
                .with(Option.P.Some(P.select()), entryParam => (
                  <BrandedApp entryParam={entryParam} />
                ))
                .exhaustive(),
            )
            .exhaustive()}
        </Suspense>
      </ClientContext.Provider>
    </ErrorBoundary>
  );
};
