// see https://github.com/github/webauthn-json/blob/v0.5.7/src/base64url.ts

export const base64urlToString = (base64url: string): string => {
  const padding = "==".slice(0, (4 - (base64url.length % 4)) % 4);
  const base64 = base64url.replace(/-/g, "+").replace(/_/g, "/") + padding;
  return window.atob(base64);
};

export const stringToBase64url = (str: string): string => {
  const base64 = window.btoa(str);
  const base64url = base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  return base64url;
};
