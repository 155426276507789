import { useMutation } from "@swan-io/graphql-client";
import { isNotNullishOrEmpty, isNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { useEffect } from "react";
import { LogoutDocument } from "../graphql/admin";
import { env } from "../utils/env";
import { safeRedirect } from "../utils/routes";
import { NotFoundPage } from "./NotFoundPage";

export const LogoutPage = ({ logoutChallenge }: { logoutChallenge: string | undefined }) => {
  const [logout] = useMutation(LogoutDocument);

  useEffect(() => {
    if (isNullishOrEmpty(logoutChallenge)) {
      return;
    }
    logout({ logoutChallenge })
      .mapOk(data => data.logout)
      .tapOk(({ redirectTo }) => {
        if (isNotNullishOrEmpty(redirectTo)) {
          // For local dev, to use our own server
          const originalLogoutUrl = new URL(redirectTo);
          const logoutUrl = new URL(env.OAUTH_SERVER_URL);
          if (originalLogoutUrl.host === logoutUrl.host) {
            return safeRedirect(redirectTo);
          }
          logoutUrl.pathname = [
            ...logoutUrl.pathname.split("/").filter(isNotNullishOrEmpty),
            ...originalLogoutUrl.pathname.split("/").filter(isNotNullishOrEmpty),
          ].join("/");
          logoutUrl.search = originalLogoutUrl.search;
          safeRedirect(logoutUrl.toString());
        }
      });
  }, [logout, logoutChallenge]);

  if (isNullishOrEmpty(logoutChallenge)) {
    return <NotFoundPage />;
  }
  return null;
};
