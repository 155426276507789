import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { BottomLink } from "../components/BottomLink";
import { Phone } from "../components/Phone";
import { desktopModeSupportArticleUrl, t } from "../utils/i18n";

export const UnsupportedLinkPage = () => (
  <>
    <Phone mainColor={colors.partner.primary} pulseColor={colors.partner[100]} />
    <Space height={72} />

    <LakeHeading level={1} variant="h3" align="center">
      {t("unsupportedPage.title")}
    </LakeHeading>

    <Space height={8} />
    <LakeText align="center">{t("unsupportedPage.text")}</LakeText>
    <Space height={16} />

    <BottomLink target="blank" to={desktopModeSupportArticleUrl}>
      {t("error.inDesktopModeLink")}
    </BottomLink>
  </>
);
