import { Link, LinkProps } from "@swan-io/lake/src/components/Link";
import { texts } from "@swan-io/lake/src/constants/design";
import { isEmpty } from "@swan-io/lake/src/utils/nullish";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { Except, SetOptional } from "type-fest";

const styles = StyleSheet.create({
  block: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginHorizontal: "auto",
    textAlign: "center",
  },
  link: {
    ...texts.smallRegular,
    textDecorationLine: "underline",
    transitionDuration: "200ms",
    transitionProperty: "opacity",
  },
  linkPressed: {
    opacity: 0.7,
  },
  disabled: {
    opacity: 0.5,
  },
});

type Props = SetOptional<Except<LinkProps, "role" | "style">, "to"> & {
  inlined?: boolean;
};

export const BottomLink = ({
  onPress,
  to = "",
  disabled = false,
  inlined = false,
  ...props
}: Props) => (
  <Link
    {...props}
    disabled={disabled}
    role={isEmpty(to) ? "button" : "link"}
    to={to}
    onPress={useCallback<NonNullable<LinkProps["onPress"]>>(
      event => {
        if (isEmpty(to)) {
          event.preventDefault();
        }

        onPress?.(event);
      },
      [to, onPress],
    )}
    style={({ pressed }) => [
      styles.link,
      !inlined && styles.block,
      disabled && styles.disabled,
      pressed && styles.linkPressed,
    ]}
  />
);
