import "@swan-io/lake/src/assets/fonts/InterCard.css";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeTooltip, TooltipRef } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Svg } from "@swan-io/lake/src/components/Svg";
import { colors, invariantColors, texts } from "@swan-io/lake/src/constants/design";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { match } from "ts-pattern";
import { CardSettingsBackgroundType } from "../graphql/exposed-internal";
import { t } from "../utils/i18n";
import { Iframe } from "./Iframe";

const CARD_BASE_WIDTH = 390;

const styles = StyleSheet.create({
  base: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 12,
    overflow: "hidden",
    paddingHorizontal: "7%",
    paddingVertical: "6%",
  },
  holder: {
    ...texts.semibold,
    lineHeight: texts.h1.lineHeight,
    color: invariantColors.white,
    maxWidth: "50%",
  },

  panBottomSpacer: {
    height: "9.5%",
    pointerEvents: "none",
  },
  bottomLineRow: {
    height: "23.5%",
    paddingRight: "32%", // mastercard logo
  },
  expiryDateWrapper: {
    width: "50%",
    alignItems: "flex-start",
  },
  cvvWrapper: {
    alignItems: "flex-start",
    width: "50%",
  },

  monospacedText: {
    color: invariantColors.white,
    fontFamily: '"Inter Card", monospace',
    lineHeight: "1" as unknown as number,
    userSelect: "none",
  },
  cvvText: {
    ...texts.regular,
    color: invariantColors.white,
    letterSpacing: "0.15em",
    lineHeight: "1" as unknown as number,
    position: "relative",
    userSelect: "none",
  },
  lightText: {
    color: colors.gray[900],
  },
  wrapperBase: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: invariantColors.transparent,
    borderRadius: 6,
    bottom: -4,
    left: -4,
    right: -4,
    top: -4,
    transitionDuration: "150ms",
    transitionProperty: "background-color",
    zIndex: -1,
  },
  wrapperHoveredLight: {
    backgroundColor: colors.gray[100],
  },
  wrapperHoveredDark: {
    backgroundColor: colors.gray[400],
  },
  wrapperPressedLight: {
    backgroundColor: colors.gray[200],
  },
  wrapperPressedDark: {
    backgroundColor: colors.gray[900],
  },
  wrapperTooltip: {
    ...texts.smallRegular,
    alignItems: "center",
    color: colors.gray.contrast,
    display: "flex",
  },
  wrapperTooltipCopied: {
    ...texts.smallSemibold,
    color: colors.positive[500],
  },
});

type IframeWrapperProps = {
  children: ReactNode;
  focused: boolean;
  hovered: boolean;
  pressed: boolean;
  theme: CardSettingsBackgroundType;
};

const IframeWrapper = ({ children, hovered, focused, pressed, theme }: IframeWrapperProps) => {
  const highlighted = hovered || focused;
  const tooltipRef = useRef<TooltipRef>(null);
  const copiedRef = useRef(false);
  const isLightTheme = theme === "Silver";

  if (!copiedRef.current && pressed) {
    copiedRef.current = true;
  }
  if (copiedRef.current && !highlighted) {
    copiedRef.current = false;
  }

  useEffect(() => {
    const tooltip = tooltipRef.current;

    if (tooltip) {
      focused ? tooltip.show() : tooltip.hide();
    }
  }, [focused]);

  return (
    <View>
      <View
        role="none"
        style={[
          styles.wrapperBase,
          highlighted && (isLightTheme ? styles.wrapperHoveredLight : styles.wrapperHoveredDark),
          pressed && (isLightTheme ? styles.wrapperPressedLight : styles.wrapperPressedDark),
        ]}
      />

      <LakeTooltip
        ref={tooltipRef}
        describedBy="copy"
        placement="center"
        togglableOnFocus={false}
        content={
          <Text style={[styles.wrapperTooltip, copiedRef.current && styles.wrapperTooltipCopied]}>
            {copiedRef.current ? (
              <>
                <Icon size={14} name="checkmark-filled" color={colors.positive[500]} />
                <Space width={8} />

                {t("tooltip.copied")}
              </>
            ) : (
              t("tooltip.copy")
            )}
          </Text>
        }
      >
        {children}
      </LakeTooltip>
    </View>
  );
};

type Props = {
  cardDesign: string;
  cvv?: string;
  expiryDate?: string;
  holderName?: string;
  pan?: string;
  theme: CardSettingsBackgroundType;
};

type MonextDataType = "pan" | "expiryDate" | "cvv";
type MonextDataMessage = "focusIn" | "focusOut" | "hoverIn" | "hoverOut" | "pressIn" | "pressOut";

type MonextData = {
  message: MonextDataMessage;
  monext: boolean;
  type: MonextDataType;
};

const initialState: Record<MonextDataType, boolean> = {
  pan: false,
  expiryDate: false,
  cvv: false,
};

export const Card = ({ cardDesign, theme, holderName, pan, expiryDate, cvv }: Props) => {
  const [ratio, setRatio] = useState(0);

  // Used to avoid triggering focus style if focus / blur event happen immediately after a press
  const pressEventTimestamp = useRef<number>(0);

  const [focused, setFocused] = useState<Record<MonextDataType, boolean>>(initialState);
  const [hovered, setHovered] = useState<Record<MonextDataType, boolean>>(initialState);
  const [pressed, setPressed] = useState<Record<MonextDataType, boolean>>(initialState);

  const lightThemed = theme === "Silver";

  const holderNameFontSize = 16 * ratio;
  const panFontSize = 23.5 * ratio;
  const expiryDateFontSize = 18 * ratio;

  const visible = ratio !== 0;

  useEffect(() => {
    const { parent } = window;

    if (visible) {
      // TODO: find why there is still a small blinking
      setTimeout(() => {
        parent.postMessage("rendered", "*");
      }, 150);
    }

    const listener = ({ data }: { data: MonextData }) => {
      if (!data.monext) {
        return;
      }

      match(data.message)
        .with("hoverIn", () => setHovered(prevState => ({ ...prevState, [data.type]: true })))
        .with("hoverOut", () => setHovered(prevState => ({ ...prevState, [data.type]: false })))
        .with("pressIn", () => {
          pressEventTimestamp.current = Date.now();
          setPressed(prevState => ({ ...prevState, [data.type]: true }));
        })
        .with("pressOut", () => {
          pressEventTimestamp.current = Date.now();
          setPressed(prevState => ({ ...prevState, [data.type]: false }));
        })
        .with("focusIn", () => {
          Date.now() - pressEventTimestamp.current > 100 &&
            setFocused(prevState => ({ ...prevState, [data.type]: true }));
        })
        .with("focusOut", () => {
          Date.now() - pressEventTimestamp.current > 100 &&
            setFocused(prevState => ({ ...prevState, [data.type]: false }));
        })
        .otherwise(() => {});
    };

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [visible]);

  return (
    <View
      onLayout={({ nativeEvent: { layout } }) => {
        // TODO: Optimize this
        setRatio(Number((layout.width / CARD_BASE_WIDTH).toFixed(2)));
      }}
    >
      {/* garantee the credit card ratio */}
      <Svg role="none" viewBox="0 0 85 55" />

      {visible && (
        <View style={styles.base}>
          <Image style={StyleSheet.absoluteFill} source={{ uri: cardDesign }} />

          <Text
            style={[
              styles.holder,
              lightThemed && styles.lightText,
              { fontSize: holderNameFontSize },
            ]}
          >
            {holderName}
          </Text>

          <Fill />

          <IframeWrapper
            hovered={hovered.pan}
            focused={focused.pan}
            pressed={pressed.pan}
            theme={theme}
          >
            <Iframe height={panFontSize} src={pan ?? ""} title="pan" width="100%" />
          </IframeWrapper>

          <View tabIndex={-1} style={styles.panBottomSpacer} />

          <Box direction="row" alignItems="center" style={styles.bottomLineRow}>
            <View style={styles.expiryDateWrapper}>
              <IframeWrapper
                focused={focused.expiryDate}
                hovered={hovered.expiryDate}
                pressed={pressed.expiryDate}
                theme={theme}
              >
                <Iframe
                  height={expiryDateFontSize}
                  src={expiryDate ?? ""}
                  title="expiry-date"
                  width={90 * ratio}
                />
              </IframeWrapper>
            </View>

            <View style={styles.cvvWrapper}>
              <Box direction="row" alignItems="center">
                <Text
                  style={[
                    styles.monospacedText,
                    lightThemed && styles.lightText,
                    { fontSize: expiryDateFontSize },
                  ]}
                >
                  <Text
                    style={[
                      styles.cvvText,
                      lightThemed && styles.lightText,
                      { fontSize: expiryDateFontSize },
                    ]}
                  >
                    {"CVC "}
                  </Text>
                </Text>

                <IframeWrapper
                  focused={focused.cvv}
                  hovered={hovered.cvv}
                  pressed={pressed.cvv}
                  theme={theme}
                >
                  <Iframe
                    height={expiryDateFontSize}
                    src={cvv ?? ""}
                    title="CVC"
                    width={62 * ratio}
                  />
                </IframeWrapper>
              </Box>
            </View>
          </Box>
        </View>
      )}
    </View>
  );
};
