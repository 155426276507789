import { AsyncData, Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { SwanLogo } from "@swan-io/lake/src/components/SwanLogo";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { EnvType } from "../graphql/admin";
import { t } from "../utils/i18n";
import { startPageViewTracking } from "../utils/matomo";

export const LAYOUT_PADDING_HORIZONTAL = 24;
export const LAYOUT_WIDTH = 480;

const easeInOutQuad = "cubic-bezier(0.45, 0, 0.55, 1)";

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
    marginHorizontal: "auto",
    maxWidth: LAYOUT_WIDTH,
    width: "100%",
    minHeight: "100%",
  },
  header: {
    paddingHorizontal: LAYOUT_PADDING_HORIZONTAL,
    paddingTop: 24,
    marginHorizontal: "auto",
  },
  main: {
    paddingHorizontal: LAYOUT_PADDING_HORIZONTAL,
    paddingVertical: 24,
  },
  partnership: {
    paddingHorizontal: LAYOUT_PADDING_HORIZONTAL,
    paddingBottom: 20,
    height: 48,
  },
  swanLogo: {
    height: 20,
    width: 90,
    marginBottom: 4,
  },
  logoContainer: {
    height: 24,
  },
  partnershipLogo: {
    display: "inline-flex",
    width: 45,
    height: 10,
  },
  footerPlaceholder: {
    height: 48,
  },
  logo: {
    opacity: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  logoVisible: {
    animationKeyframes: {
      from: {
        opacity: 0,
      },
    },
    animationDuration: "300ms",
    animationTimingFunction: easeInOutQuad,
    opacity: 1,
  },
  footerVisible: {
    animationKeyframes: {
      from: {
        opacity: 0,
      },
    },
    animationDuration: "300ms",
    animationTimingFunction: easeInOutQuad,
  },
});

type Props = {
  children: ReactNode;
  clientLogo?: string | undefined;
  clientName?: string | undefined;
  envType: EnvType;
};

const PartnershipMention = () => (
  <Box
    role="contentinfo"
    alignItems="baseline"
    direction="row"
    justifyContent="center"
    style={styles.partnership}
  >
    <LakeText align="center" variant="smallRegular">
      {t("partnershipMention.text")}
    </LakeText>

    <Space width={4} />
    <SwanLogo color={colors.gray[500]} style={styles.partnershipLogo} />
  </Box>
);

export const Layout = ({ children, clientLogo, clientName, envType }: Props) => {
  const [hasIframeBody, setHasIframeBody] = useState(false);

  const [logoDimensions, setLogoDimensions] = useState<
    AsyncData<Option<{ width: number; height: number }>>
  >(AsyncData.NotAsked());

  const [isLogoLoaded, setIsLogoLoaded] = useState(false);

  useEffect(() => {
    return match(clientLogo)
      .with(P.select(P.string), url => {
        setLogoDimensions(AsyncData.Loading());
        const img = document.createElement("img");
        const onLoad = () => {
          setLogoDimensions(
            AsyncData.Done(
              Option.Some({ height: 24, width: (img.naturalWidth / img.naturalHeight) * 24 }),
            ),
          );
        };
        const onError = () => {
          setLogoDimensions(AsyncData.Done(Option.None()));
        };
        img.addEventListener("load", onLoad);
        img.addEventListener("error", onError);
        img.src = url;
        return () => {
          img.removeEventListener("load", onLoad);
          img.removeEventListener("error", onError);
        };
      })
      .otherwise(() => {});
  }, [clientLogo]);

  useEffect(() => {
    startPageViewTracking(envType);
  }, [envType]);

  useLayoutEffect(() => {
    const isKnownVendorIframe = (node: Node) =>
      node instanceof HTMLIFrameElement &&
      (node.src.includes("ubble.ai") ||
        node.src.includes("fourthline.com") ||
        node.src.includes("cemacarte.fr"));

    const observer = new MutationObserver(mutations => {
      const removedIframe = mutations.find(({ removedNodes }) =>
        isNotNullish([...removedNodes].find(isKnownVendorIframe)),
      );

      if (isNotNullish(removedIframe)) {
        return setHasIframeBody(false);
      }

      const addedIframe = mutations.find(({ addedNodes }) =>
        isNotNullish([...addedNodes].find(isKnownVendorIframe)),
      );

      if (isNotNullish(addedIframe)) {
        return setHasIframeBody(true);
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.content}>
      <Box direction="row" role="banner" alignItems="center" style={styles.header}>
        {match(clientLogo)
          .with(P.nullish, () => <SwanLogo style={[styles.swanLogo, styles.logoVisible]} />)
          .with(P.string, url => (
            <View style={styles.logoContainer}>
              {match(logoDimensions)
                .with(AsyncData.P.Done(Option.P.Some(P.select())), ({ width, height }) => (
                  <View style={[styles.logo, isLogoLoaded && styles.logoVisible]}>
                    <Image
                      style={{ width, height }}
                      resizeMode="contain"
                      source={url}
                      alt={clientName}
                      onLoad={() => setIsLogoLoaded(true)}
                    />

                    {envType === "Sandbox" && (
                      <>
                        <Space width={12} />
                        <Tag color="sandbox" ariaLabel={envType} icon="beaker-regular" />
                      </>
                    )}
                  </View>
                ))
                .with(AsyncData.P.Done(Option.P.None), () =>
                  envType === "Sandbox" ? (
                    <Tag color="sandbox" ariaLabel={envType} icon="beaker-regular" />
                  ) : null,
                )
                .otherwise(() => null)}
            </View>
          ))
          .exhaustive()}

        {hasIframeBody && <Space height={20} />}
      </Box>

      <Box
        role="main"
        justifyContent="center"
        style={[commonStyles.fill, !hasIframeBody && styles.main]}
      >
        {children}
      </Box>

      {match({ logoDimensions, isLogoLoaded, hasIframeBody })
        .with({ hasIframeBody: true }, () => null)
        .with(
          { logoDimensions: AsyncData.P.Done(Option.P.Some(P._)), isLogoLoaded: true },
          { logoDimensions: AsyncData.P.Done(Option.P.None) },
          () => (
            <View style={styles.footerVisible}>
              <PartnershipMention />
            </View>
          ),
        )
        .otherwise(() => (
          <View style={styles.footerPlaceholder} />
        ))}
    </ScrollView>
  );
};
