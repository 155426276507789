import { BorderedIcon } from "@swan-io/lake/src/components/BorderedIcon";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { t } from "../utils/i18n";

export const DonePage = () => {
  return (
    <Box alignItems="center" justifyContent="center" grow={1}>
      <BorderedIcon color="positive" name="lake-check" padding={24} size={100} />
      <Space height={24} />

      <LakeHeading level={1} variant="h3" align="center">
        {t("successPage.title")}
      </LakeHeading>

      <Space height={8} />
      <LakeText align="center">{t("successPage.text")}</LakeText>
    </Box>
  );
};
