import { AsyncData, Result } from "@swan-io/boxed";
import { ClientError, useQuery } from "@swan-io/graphql-client";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import {
  ChoosePhysicalCardPinLive,
  ChoosePhysicalCardPinSandbox,
} from "../components/SensitiveInfoViewer";
import { EnvType } from "../graphql/admin";
import { ChoosePinDetailsDocument } from "../graphql/exposed-internal";
import { ExpiredLinkPage } from "./ExpiredLinkPage";

type Props = {
  token: string;
  env: EnvType;
};

export const CardChoosePinPage = ({ token, env }: Props) => {
  const [data] = useQuery(ChoosePinDetailsDocument, { input: { token } });

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => null)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => {
      if (ClientError.toArray(error).some(error => error.message === "token is expired")) {
        return <ExpiredLinkPage recoverable={false} />;
      }
      return <ErrorView error={error} />;
    })
    .with(AsyncData.P.Done(Result.P.Ok({ choosePinDetails: P.nullish })), () => <ErrorView />)
    .with(
      AsyncData.P.Done(Result.P.Ok({ choosePinDetails: P.select(P.nonNullable) })),
      ({ token, productCode }) => {
        return match(env)
          .with("Live", () => <ChoosePhysicalCardPinLive token={token} productCode={productCode} />)
          .with("Sandbox", () => <ChoosePhysicalCardPinSandbox />)
          .exhaustive();
      },
    )
    .exhaustive();
};
