import { SeverityLevel, User } from "@sentry/browser";
import { P, match } from "ts-pattern";
import { env } from "./env";

const sentry = import("@sentry/browser");

export const setSentryUser = (user: User) => {
  void sentry.then(({ setUser }) => setUser(user));
};

export const initSentry = () => {
  void sentry.then(({ init }) => {
    init({
      enabled: import.meta.env.PROD,
      release: env.VERSION,
      dsn: "https://525c49f23a9746838a1e3ba8bd9a5bd3@o427297.ingest.sentry.io/5432394",
      normalizeDepth: 5,

      environment: match({
        dev: import.meta.env.DEV,
        url: env.IDENTITY_URL,
      })
        .with({ dev: true }, () => "dev")
        .with({ url: P.string.includes("master") }, () => "master")
        .with({ url: P.string.includes("preprod") }, () => "preprod")
        .otherwise(() => "prod"),
    });
  });
};

type Context = Partial<{
  level: SeverityLevel;
  tags: Record<string, string>;
  extra: Record<string, unknown>;
}>;

export const logFrontendError = (exception: Error, { extra, level, tags }: Context = {}) => {
  void sentry.then(({ captureException }) => {
    captureException(exception, { extra, level, tags });
  });
};
