import { BorderedIcon } from "@swan-io/lake/src/components/BorderedIcon";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { Layout } from "../components/Layout";
import { t } from "../utils/i18n";

type Props = {
  recoverable?: boolean;
};

export const ExpiredLinkPage = ({ recoverable = true }: Props) => (
  <Layout envType="Live">
    <BorderedIcon color="warning" name="lake-clock" size={100} style={commonStyles.centerSelf} />
    <Space height={24} />

    <LakeHeading level={1} variant="h3" align="center">
      {t("expiredLinkPage.title")}
    </LakeHeading>

    {recoverable ? (
      <>
        <Space height={8} />
        <LakeText align="center">{t("expiredLinkPage.text")}</LakeText>
      </>
    ) : null}
  </Layout>
);
