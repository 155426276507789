import { colors } from "@swan-io/lake/src/constants/design";
import { stubFalse, stubTrue } from "@swan-io/lake/src/utils/function";
import { useEffect, useLayoutEffect, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { env } from "../utils/env";

const DEBUG = match({ dev: import.meta.env.DEV, url: env.IDENTITY_URL })
  .with({ dev: true }, { url: P.string.includes("master") }, stubTrue)
  .otherwise(stubFalse);

const styles = StyleSheet.create({
  base: {
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
  },
  animation: {
    animationDuration: "0.75s",
    animationKeyframes: [
      {
        "0%": { transform: "rotate(0deg)" },
        "100%": { transform: "rotate(360deg)" },
      },
    ],
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
});

const createSvgCircle = (style: Record<string, string | number>) => (
  <circle cx="16" cy="16" fill="none" r="14" strokeWidth="4" style={style} />
);

type Props = {
  id: string;
  color?: string;
  meta?: Record<string, unknown>;
};

export const LoadingView = ({ id, color = colors.gray[500], meta }: Props) => {
  const svg = (
    <svg height={20} width={20} viewBox="0 0 32 32">
      {createSvgCircle({
        stroke: color,
        opacity: 0.2,
      })}

      {createSvgCircle({
        stroke: color,
        strokeDasharray: 80,
        strokeDashoffset: 60,
      })}
    </svg>
  );

  const animationRef = useRef<View | null>(null);

  useLayoutEffect(() => {
    if (animationRef.current != null) {
      const element = animationRef.current as unknown as HTMLElement;

      if (typeof element.getAnimations === "function") {
        const animation = element.getAnimations?.()[0];

        if (animation != null) {
          animation.startTime = 0;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (DEBUG) {
      console.log(`Loading ID: ${id}`, ...(meta != null ? [meta] : []));
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <View style={styles.base} role="progressbar">
      <View style={styles.animation} ref={animationRef}>
        {svg}
      </View>
    </View>
  );
};
