import { Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { BorderedIcon } from "@swan-io/lake/src/components/BorderedIcon";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { useEffect, useState } from "react";
import { P, match } from "ts-pattern";
import { RestartLoginDocument } from "../graphql/admin";
import { t } from "../utils/i18n";
import { EntryParam, InitialFlowUrl } from "../utils/session";

export const LogoutCallbackPage = () => {
  const [restartLogin] = useMutation(RestartLoginDocument);

  const [loginChallenge] = useState(() => EntryParam.get());
  const [initialFlowUrl] = useState(() => InitialFlowUrl.get());

  useEffect(() => {
    match(loginChallenge)
      .with(Option.P.Some({ loginChallenge: P.select() }), loginChallenge =>
        restartLogin({ input: { loginChallenge } }).tap(() => {
          // This should run no matter the outcome of `restartLogin`
          initialFlowUrl.tapSome(redirectTo => {
            // new flow, clear session storage
            window.sessionStorage.clear();
            window.location.replace(redirectTo);
          });
        }),
      )
      .otherwise(() => {});
  }, [restartLogin, loginChallenge, initialFlowUrl]);

  return match({ loginChallenge, initialFlowUrl })
    .with({ loginChallenge: Option.P.None }, { initialFlowUrl: Option.P.None }, () => (
      <Box alignItems="center" justifyContent="center" grow={1}>
        <BorderedIcon color="positive" name="lake-check" padding={24} size={100} />
        <Space height={24} />

        <LakeHeading level={1} variant="h3" align="center">
          {t("successPage.title")}
        </LakeHeading>

        <Space height={8} />
        <LakeText align="center">{t("successPage.text")}</LakeText>
      </Box>
    ))
    .otherwise(() => null);
};
