import { Option, Result, Serializer } from "@swan-io/boxed";

export type EntryParam =
  // OAuth2
  | { loginChallenge: string }
  | { consentChallenge: string }
  // Operations
  | { consentId: string; env: "Sandbox" | "Live" };

export type Source = "App" | "Direct" | "QRCode" | "SMS";

export type OtpParams = {
  code: string;
  expireAt?: string;
  requestId: string;
};

const createSessionStorage = <T>(name: string) => {
  const set = (value: T) => {
    Result.fromExecution(() => Serializer.encode(value)).tapOk(json =>
      window.sessionStorage.setItem(name, json),
    );
  };

  const update = (updater: (value: Option<T>) => T) => {
    set(updater(get()));
  };

  const get = () =>
    Option.fromNullable(window.sessionStorage.getItem(name)).flatMap(value =>
      Result.fromExecution(() => Serializer.decode(value) as T).toOption(),
    );

  const delete_ = () => {
    window.sessionStorage.removeItem(name);
  };

  return { set, update, get, delete: delete_ };
};

const createLocalStorage = <T>(name: string) => {
  const set = (value: T) => {
    Result.fromExecution(() => Serializer.encode(value)).flatMap(json =>
      Result.fromExecution(() => window.localStorage.setItem(name, json)),
    );
  };

  const update = (updater: (value: Option<T>) => T) => {
    set(updater(get()));
  };

  const get = () =>
    Result.fromExecution(() => window.localStorage.getItem(name))
      .toOption()
      .flatMap(value => Option.fromNullable(value))
      .flatMap(value => Result.fromExecution(() => Serializer.decode(value) as T).toOption());

  const delete_ = () => {
    Result.fromExecution(() => window.localStorage.removeItem(name));
  };

  return { set, update, get, delete: delete_ };
};

export const EntryParam = createSessionStorage<EntryParam>("EntryParam");
export const Source = createSessionStorage<Source>("Source");
export const OtpParams = createSessionStorage<OtpParams>("OtpParams");
export const MobilePhoneNumber = createSessionStorage<string>("MobilePhoneNumber");
export const InitialFlowUrl = createSessionStorage<string>("InitialFlowUrl");
export const SubcriptionChannelNameByConsentId = createLocalStorage<
  Record<string, { channelName: string; expireAt: number }>
>("SubcriptionChannelNameByConsentId");
export const HasRegisteredPasscodeInFlow = createSessionStorage<boolean>(
  "HasRegisteredPasscodeInFlow",
);
